require('./scss/main.scss');
require('fullpage.js/dist/jquery.fullpage.css');

require('jquery/dist/jquery.min.js');
require('what-input/dist/what-input.min.js');
require('foundation-sites/dist/js/plugins/foundation.core.js');
require('foundation-sites/dist/js/plugins/foundation.reveal.js');
require('foundation-sites/dist/js/plugins/foundation.toggler.js');
require('foundation-sites/dist/js/plugins/foundation.util.box.js');
require('foundation-sites/dist/js/plugins/foundation.util.keyboard.js');
require('foundation-sites/dist/js/plugins/foundation.tooltip.js');
require('foundation-sites/dist/js/plugins/foundation.util.mediaQuery.js');
require('foundation-sites/dist/js/plugins/foundation.util.motion.js');
require('foundation-sites/dist/js/plugins/foundation.util.triggers.js');

require('fullpage.js/dist/jquery.fullpage.min.js');
require('fullpage.js/vendors/scrolloverflow.min.js');

require('./js/app.js');

if (module.hot) {
    module.hot.accept();
}
