$(document).ready(function() {
  $(document).foundation();
  $('#fullpage').fullpage({
    //anchors: ['firstPage', 'secondPage', '3rdPage'],
    sectionsColor: [
      '#3D3731',
      '#ffffff',
      '#3D3731',
      '#FF9933',
      '#3D3731',
      '#ffffff',
      '#FF9933',
      '#ffffff'
    ],
    navigation: true,
    navigationPosition: 'right',
    navigationTooltips: ['Accueil', 'L\'agence', 'Références', 'Compétences', 'Technologies', 'Infrastructure', 'Gestion de projet', 'Contact'],
    autoScrolling: false,
    fitToSection: false,
    scrollOverflow: true,
    normalScrollElements: '.reveal-overlay, .scrollable',
    responsive: 950
  });
  $(document).on('click', '.ending', function(){
    //$.fn.fullpage.moveSectionDown();
    $('.tooltip').hide();
  });
  //AOS.init({
  //  duration: 1200,
  //})
});
